<template>
  <div>
    <p ref="pHidden" hidden>Monto:{{ monto }}</p>
    <v-text-field
      v-model="monto"
      ref="inputRef"
      :class="classDynamic"
      :outlined="bOutlined"
      :dense="bDense"
      :disabled="bDisabled"
      :label="labelGlobal"
      placeholder="$0.00"
      :min="0"
      :max="9999999"
      maxlength="16"
      :suffix="sSuffix"
      :prefix="sPrefix"
      hide-details=""
      color="#C6C6C8"
      @keyup="changeMontoGlobal"
    >
      <template slot="label">
        <span
          >{{ labelGlobal
          }}<span class="important-data" v-show="bImportantData">*</span></span
        >
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    flagModal: Boolean,
    emptyValue: { type: Boolean, default: false },
    modelValue: Number, // Vue 2: value
    options: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return {
          locale: "en-US",
          currency: "USD",
          currencyDisplay: "hidden",
          hideCurrencySymbolOnFocus: false,
          hideGroupingSeparatorOnFocus: true,
          hideNegligibleDecimalDigitsOnFocus: true,
          autoDecimalDigits: false,
          autoSign: true,
          useGrouping: true,
          accountingSign: false,
        };
      },
    },
    iIndex:Number,
    classDynamic: String,
    bOutlined: { type: Boolean, default: false },
    bDense: { type: Boolean, default: false },
    bDisabled: { type: Boolean, default: false },
    bRefreshValue: { type: Boolean, default: false },
    labelGlobal: { type: String, default: "Monto" },
    bImportantData: { type: Boolean, default: false },
    sPrefix: { type: String, default: "$" },
    sSuffix: { type: String, default: "MXN" },
  },
  data() {
    return {
      monto: 0,
    };
  },
   setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
  beforeMount() {
    if (this.flagModal) {
      this.getValue();
    }
  },
  methods: {
    getValue() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.monto = this.modelValue;
          this.bLoading = true;
        });
      }, 500);
    },
    changeMontoGlobal() {
      if (this.monto === '') {
        this.monto = 0
      }
      this.$emit("changeMontoGlobal", {
        value: this.monto,
        index: this.iIndex
      });
    },
  },
  watch: {
    flagModal() {
      if (this.flagModal) {
        this.monto = 0;
        this.getValue();
      }
    },
    emptyValue() {
      this.monto = 0;
      this.$emit("changeMontoGlobal", 0);
    },
    bRefreshValue() {
      this.getValue();
    },
  },
};
</script>

<style>
</style>